// components/BookingList.js
import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import BookingDetails from "./BookingDetails"; // Import the BookingDetails component
import { GoogleMap, Marker, DirectionsRenderer, useJsApiLoader } from "@react-google-maps/api"; // Ensure necessary imports

const BookingList = () => {
  const [bookings, setBookings] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 10;
  const [loading, setLoading] = useState(false);

  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [isDriverModalOpen, setIsDriverModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [drivers, setDrivers] = useState([]);
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(1);

  const token = localStorage.getItem("token");

  // Fetch bookings with pagination
  const fetchBookings = useCallback(async () => {
    setLoading(true);
    try {
      // Ensure page is a number
      const response = await axios.get(`https://api.ramveer.dev/api/bookings?page=${page}&limit=${limit}`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      if (response.status === 200) {
        setBookings(response.data.bookings);
      }
    } catch (error) {
      console.error("Failed to fetch bookings:", error);
      toast.error("Failed to fetch bookings.");
    }
    setLoading(false);
  }, [page, limit, token]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  // Handle View Button Click
  const handleViewClick = (booking) => {
    setSelectedBooking(booking);
    setIsDrawerOpen(true);
  };

  // Close Drawer
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedBooking(null);
  };

  // Pagination Handlers
  const handlePrevPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    // If less than limit, probably no more pages:
    if (bookings.length === limit) {
      setPage(page + 1);
    }
  };

  // Open Driver Assignment Modal
  const openDriverModal = async (booking) => {
    setSelectedBooking(booking);
    try {
      const response = await axios.get(`https://api.ramveer.dev/api/drivers?typeOfVehicle=${booking.vehicleType}`, {
        headers: {
          Authorization: `${token}`,
        },
      });
      if (response.status === 200) {
        setDrivers(response.data); // Assumes response has {drivers: []}
      }
    } catch (error) {
      console.error("Failed to fetch drivers:", error);
      toast.error("Failed to fetch drivers.");
    }
    setIsDriverModalOpen(true);
  };

  const closeDriverModal = () => {
    setIsDriverModalOpen(false);
    setSelectedDriverId(null);
  };

  // Assign Driver
  const assignDriver = async () => {
    if (!selectedBooking || !selectedDriverId) {
      toast.error("Please select a driver.");
      return;
    }
    try {
      // Patch the booking to update driverId
      await axios.put(
        `https://api.ramveer.dev/api/bookings/${selectedBooking.id}`,
        {
          driverId: selectedDriverId,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Driver assigned successfully");
      closeDriverModal();
      fetchBookings(); // refresh
    } catch (error) {
      console.error("Failed to assign driver:", error);
      toast.error("Failed to assign driver.");
    }
  };

  // Open Status Update Modal
  const openStatusModal = (booking) => {
    setSelectedBooking(booking);
    setIsStatusModalOpen(true);
  };

  const closeStatusModal = () => {
    setIsStatusModalOpen(false);
    setSelectedStatus(1);
  };

  // Get Status Text
  const getStatus = (status) => {
    switch (status) {
      case 1:
        return "Assigned";
      case 2:
        return "Transit";
      case 3:
        return "Completed";
      case 4:
        return "Cancelled";
      default:
        return "Pending";
    }
  };

  // Update Booking Status
  const updateStatus = async () => {
    if (!selectedBooking) return;
    try {
      // Patch the booking to update status
      await axios.put(
        `https://api.ramveer.dev/api/bookings/${selectedBooking.id}`,
        {
          status: selectedStatus,
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Status updated successfully");
      closeStatusModal();
      fetchBookings(); // refresh
    } catch (error) {
      console.error("Failed to update status:", error);
      toast.error("Failed to update status.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center mt-24">
        <div className="text-xl font-semibold">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex justify-center mt-24 relative">
      <div className="w-full max-w-6xl bg-white shadow-lg rounded-lg overflow-hidden">
        <h3 className="text-xl font-semibold text-center py-4 bg-gray-800 text-white">List of Bookings</h3>
        {bookings.length === 0 ? (
          <div className="p-4 text-center">No Bookings Found</div>
        ) : (
          <>
            <table className="min-w-full table-auto">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-left">Booking ID</th>
                  <th className="px-4 py-2 text-left">Status</th>
                  <th className="px-4 py-2 text-left">Driver</th>
                  <th className="px-4 py-2 text-left">From Location</th>
                  <th className="px-4 py-2 text-left">To Location</th>
                  <th className="px-4 py-2 text-left">Distance (km)</th>
                  <th className="px-4 py-2 text-left">Fare(Rs)</th>
                  <th className="px-4 py-2 text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {bookings.map((booking) => (
                  <tr key={booking.id} className="border-t">
                    <td className="px-4 py-2">{booking.id}</td>
                    <td className="px-4 py-2">
                      {getStatus(booking.status)}
                    </td>
                    <td className="px-4 py-2">
                      {booking.driverId
                        ? `${booking.driver?.user?.name} (${booking.driver?.user?.mobile})`
                        : "N/A"}
                    </td>
                    <td className="px-4 py-2 whitespace-normal">{booking.fromLocationName}</td>
                    <td className="px-4 py-2 whitespace-normal">{booking.toLocationName}</td>
                    <td className="px-4 py-2">{(booking.distance / 1000).toFixed(2)}</td>
                    <td className="px-4 py-2">Rs. {booking.fareEstimate}</td>
                    <td className="px-4 py-2 space-x-2">
                      {booking.driverId == null ? (
                        <button
                          onClick={() => openDriverModal(booking)}
                          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                        >
                          Assign Driver
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() => openDriverModal(booking)}
                            className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600"
                          >
                            Reassign Driver
                          </button>
                          <button
                            onClick={() => openStatusModal(booking)}
                            className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                          >
                            Update Status
                          </button>
                        </>
                      )}
                      <button
                        onClick={() => handleViewClick(booking)}
                        className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between items-center p-4">
              <button
                onClick={handlePrevPage}
                disabled={page === 1}
                className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md transition disabled:opacity-50"
              >
                Previous
              </button>
              <span>Page: {page}</span>
              <button
                onClick={handleNextPage}
                disabled={bookings.length < limit}
                className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md transition disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </>
        )}
      </div>

      {/* Side Drawer for Viewing Booking Details */}
      {isDrawerOpen && selectedBooking && (
        <div className="fixed inset-0 flex z-50">
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={handleCloseDrawer}
          ></div>

          {/* Drawer */}
          <div className="relative ml-auto bg-white w-full max-w-4xl h-full shadow-lg overflow-y-auto">
            <button
              onClick={handleCloseDrawer}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 text-2xl"
            >
              &times;
            </button>
            <BookingDetails booking={selectedBooking} />
          </div>
        </div>
      )}

      {/* Driver Modal */}
      {isDriverModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Assign/Reassign Driver</h2>
            <select
              className="border rounded w-full p-2 mb-4"
              value={selectedDriverId || ""}
              onChange={(e) => setSelectedDriverId(e.target.value)}
            >
              <option value="">Select a driver</option>
              {drivers.map((driver) => (
                <option key={driver.id} value={driver.id}>
                  {driver.user.name} ({driver.user.mobile})
                </option>
              ))}
            </select>
            <div className="flex justify-end space-x-2">
              <button
                onClick={closeDriverModal}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={assignDriver}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Assign
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Status Modal */}
      {isStatusModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Update Status</h2>
            <select
              className="border rounded w-full p-2 mb-4"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(parseInt(e.target.value))}
            >
              <option value={1}>Assigned</option>
              <option value={2}>Transit</option>
              <option value={3}>Completed</option>
              <option value={4}>Cancelled</option>
            </select>
            <div className="flex justify-end space-x-2">
              <button
                onClick={closeStatusModal}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={updateStatus}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default BookingList;
