// components/YourBookings.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import BookingDetails from "./BookingDetails"; // Import the BookingDetails component
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const YourBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // You can make this dynamic or configurable if needed.
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const token = localStorage.getItem("token");

  const fetchBookings = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.ramveer.dev/api/bookings/user?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      // The API returns page, limit, and bookings array
      if (response.status === 200) {
        setBookings(response.data.bookings);
      }
    } catch (error) {
      console.error("Failed to fetch user bookings:", error);
      toast.error("Failed to fetch bookings.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBookings();
  }, [page, limit]); // refetch when page or limit changes

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    // If we got fewer than 'limit' bookings, it means no more pages
    if (bookings.length === limit) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return "Assigned";
      case 2:
        return "Transit";
      case 3:
        return "Completed";
      case 4:
        return "Cancelled";
      default:
        return "Pending";
    }
  };

  const openCancelModal = (booking) => {
    setSelectedBooking(booking);
    setIsModalOpen(true);
  };

  const closeCancelModal = () => {
    setSelectedBooking(null);
    setIsModalOpen(false);
  };

  const openDrawer = (booking) => {
    setSelectedBooking(booking);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedBooking(null);
    setIsDrawerOpen(false);
  };

  const updateStatus = async () => {
    if (!selectedBooking) return;
    try {
      // Patch the booking to update status
      await axios.put(
        `https://api.ramveer.dev/api/bookings/${selectedBooking.id}`,
        {
          status: 4, 
        },
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Booking cancelled successfully.");
      closeCancelModal();
      fetchBookings(); // refresh
    } catch (error) {
      console.error("Failed to update status:", error);
      toast.error("Failed to cancel booking.");
    }
  };

  if (loading) {
    return (
      <div className="mt-8 max-w-4xl mx-auto p-4 bg-white shadow-lg rounded-lg">
        <h3 className="text-xl font-semibold text-center py-4 bg-gray-800 text-white rounded-t-lg">
          Your Bookings
        </h3>
        <p className="p-4 text-center">Loading...</p>
        <ToastContainer />
      </div>
    );
  }

  if (!bookings || bookings.length === 0) {
    return (
      <div className="mt-8 max-w-4xl mx-auto p-4 bg-white shadow-lg rounded-lg">
        <h3 className="text-xl font-semibold text-center py-4 bg-gray-800 text-white rounded-t-lg">
          Your Bookings
        </h3>
        <p className="p-4 text-center">No bookings found.</p>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="mt-8 max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden relative">
      <h3 className="text-xl font-semibold text-center py-4 bg-gray-800 text-white">
        Your Bookings
      </h3>
      <table className="min-w-full table-auto">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-2 text-left">Booking ID</th>
            <th className="px-4 py-2 text-left">From</th>
            <th className="px-4 py-2 text-left">To</th>
            <th className="px-4 py-2 text-left">Distance (km)</th>
            <th className="px-4 py-2 text-left">Fare(Rs.)</th>
            <th className="px-4 py-2 text-left">Status</th>
            <th className="px-4 py-2 text-left">Actions</th> {/* New Actions Column */}
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking) => (
            <tr key={booking.id} className="border-t">
              <td className="px-4 py-2">{booking.id}</td>
              <td className="px-4 py-2 whitespace-normal">{booking.fromLocationName}</td>
              <td className="px-4 py-2 whitespace-normal">{booking.toLocationName}</td>
              <td className="px-4 py-2">{(booking.distance / 1000).toFixed(2)}</td>
              <td className="px-4 py-2">Rs. {booking.fareEstimate}</td>
              <td className="px-4 py-2 capitalize">{getStatus(booking.status)}</td>
              <td className="px-4 py-2">
                {
                  !(booking.status === 2 || booking.status === 3 || booking.status === 4) &&
                  <button
                    onClick={() => openCancelModal(booking)}
                    className="px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
                  >
                    Cancel
                  </button>
                }
                {/* <button
                  onClick={() => openCancelModal(booking)}
                  disabled={booking.status === 2 || booking.status === 3 || booking.status === 4}
                  className={`mr-2 px-3 py-1 bg-red-500 text-white rounded-md ${
                    booking.status === 2 || booking.status === 3 || booking.status === 4
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-red-600"
                  }`}
                >
                  Cancel
                </button> */}
                <button
                  onClick={() => openDrawer(booking)}
                  className="px-3 py-1 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center p-4">
        <button
          onClick={handlePrevPage}
          disabled={page === 1}
          className={`px-4 py-2 bg-blue-600 text-white font-semibold rounded-md transition disabled:opacity-50 mr-2`}
        >
          Previous
        </button>
        <span>Page: {page}</span>
        <button
          onClick={handleNextPage}
          disabled={bookings.length < limit}
          className={`px-4 py-2 bg-blue-600 text-white font-semibold rounded-md transition disabled:opacity-50 ml-2`}
        >
          Next
        </button>
      </div>

      {/* Cancel Confirmation Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 w-80">
            <h2 className="text-xl font-semibold mb-4">Confirm Cancellation</h2>
            <p>Are you sure you want to cancel booking ID: {selectedBooking.id}?</p>
            <div className="flex justify-end mt-6">
              <button
                onClick={closeCancelModal}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md mr-2"
              >
                Cancel
              </button>
              <button
                onClick={updateStatus}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {/* View Details Drawer */}
      {isDrawerOpen && (
        <div className="fixed inset-0 flex">
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black opacity-50"
            onClick={closeDrawer}
          ></div>

          {/* Drawer */}
          <div className="relative ml-auto bg-white w-2/3 h-full shadow-lg overflow-y-auto">
            <button
              onClick={closeDrawer}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              {/* Close Icon (you can replace with an actual icon) */}
              ×
            </button>
            <BookingDetails booking={selectedBooking} />
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default YourBookings;
