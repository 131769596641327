import React, { useEffect, useState } from "react";
import Button from "./Button";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import usestepStore from "../store/step";
import useRouteStore from "../store/route";
import BookSkelton from "./BookSkelton";
import tataace from "../assets/tataace.webp";
import bolero from "../assets/bolero.webp";
import { toast } from "react-toastify";
import axios from "axios";

const Book = () => {
  const data = [
    {
      id: "tataace",
      title: "Chhota Hathi",
      image: tataace,
      capacity: 2000,
      // Assuming morningRate/nightRate logic no longer needed,
      // or if still needed, define them and incorporate if desired.
    },
    {
      id: "pickup",
      title: "Pick-up",
      image: bolero,
      capacity: 3000,
    },
  ];
  
  const { current, setStep } = usestepStore((state) => ({
    current: state.current,
    setStep: state.setStep,
  }));

  const [selected, setSelected] = useState(null);
  
  const { route, state } = useRouteStore((store) => ({
    route: store.route,
    state: store,
  }));

  useEffect(() => {
    console.log(state, "state");
  }, [state]);

  const { setDirection, loading } = useRouteStore((state) => ({
    setDirection: state.setDirection,
    loading: state.loading,
  }));

  const calculatePrice = (vehicle, route) => { 
    const distanceKm = route.distanceValue / 1000; // in kms
    const stopsCount = route.stops ? route.stops.length : 0; // in number of stops
    const returnJourney = route.rawData.returnJourney ? 1 : 0; // 1 if return journey, else 0
    const vehicleType = vehicle.id.toLowerCase(); // "tataace" or "pickup"

    let totalPrice = 0;

    // Helper function to calculate TataAce price
    const calculateTataAcePrice = (distance) => {
        if (distance <= 5) {
            return 550;
        } else if (distance <= 30) {
            return 550 + (distance - 5) * 60;
        } else {
            return 2050 + (distance - 30) * 50;
        }
    };

    // Helper function to calculate Pickup price
    const calculatePickupPrice = (distance) => {
        if (distance <= 4) {
            return 500;
        } else if (distance <= 8) {
            return 800;
        } else if (distance <= 24) {
            return 800 + (distance - 8) * 90;
        } else {
            // Calculate price up to 24 km, then add the additional distance
            const priceUpTo24 = 800 + (24 - 8) * 90; // 800 + 16*90 = 2240
            return priceUpTo24 + (distance - 24) * 75;
        }
    };

    // Helper function to calculate Return Journey Fee
    const calculateReturnJourneyFee = (distance) => {
        return distance > 8 ? 400 : 300;
    };

    // Calculate base price based on vehicle type
    if (vehicleType === 'tataace') {
        totalPrice = calculateTataAcePrice(distanceKm);
    } else if (vehicleType === 'pickup') {
        totalPrice = calculatePickupPrice(distanceKm);
    } else {
        throw new Error(`Unknown vehicle type: ${vehicleType}`);
    }

    // Add return journey fee if applicable
    if (returnJourney === 1) {
        totalPrice += calculateReturnJourneyFee(distanceKm);
    }

    return totalPrice;
};


  const onBookClick = async () => {
    const selectedVehicle = data[selected];

    const stopsArray = route.stops ? route.stops : [];
    const returnJourney = route.rawData.returnJourney ? 1 : 0;

    const booking = {
      fromLocation: route.from,
      toLocation: route.to,
      vehicleType: selectedVehicle.id,
      fromLocationName: route.rawData.source,
      toLocationName: route.rawData.destination,
      distance: route.distanceValue,
      fareEstimate: calculatePrice(selectedVehicle, route),
      duration: route.duration,
      // Include the 'other' field as requested
      other: {
        stops: stopsArray,            // Array of { lat, lng } for each stop
        returnJourney: returnJourney, // 1 if return journey, else 0
      },
    };

    try {
      const response = await axios.post(
        "https://api.ramveer.dev/api/bookings/requestmanual",
        booking,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success(`${selectedVehicle.title} booked successfully`);
        console.log(response.data);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
      console.error(error);
    }

    // Reset state and navigate to the next step
    setStep("Form");
    setDirection(undefined);
  };

  if (current === "Book") {
    return (
      <div className="absolute top-24 left-7 bg-white rounded-lg p-5 shadow-lg w-80 border border-gray-200">
        {loading ? (
          <>
            <BookSkelton />
            <BookSkelton />
            <BookSkelton />
            <BookSkelton />
          </>
        ) : (
          <div>
            <div className="flex items-center mb-4">
              <ArrowLeftCircleIcon
                onClick={() => {
                  setStep("Form");
                  setDirection(undefined);
                }}
                className="w-7 h-7 text-gray-600 cursor-pointer hover:text-gray-800 transition"
              />
              <h4 className="mx-auto text-lg font-bold text-gray-700">
                Select Ride
              </h4>
            </div>
            <div className="flex justify-between mb-4 text-gray-800 text-sm">
              <div>
                Estimated Time: <span className="font-bold">{route.duration}</span>
              </div>
              <div>
                Distance: <span className="font-bold">{route.distance}</span>
              </div>
            </div>
            {data.map((vehicle, index) => {
              const price = calculatePrice(vehicle, route);
              return (
                <div
                  key={index}
                  className={`flex items-center p-3 mb-3 rounded-md shadow-sm border transition hover:border-gray-500 cursor-pointer ${
                    selected === index ? "border-gray-600" : "border-gray-300"
                  }`}
                  onClick={() => setSelected(index)}
                >
                  <img className="w-16 h-16 mr-3 object-contain" src={vehicle.image} alt={vehicle.title} />
                  <div className="flex-1">
                    <h3 className="text-base font-semibold text-gray-700">{vehicle.title}</h3>
                    <div className="text-sm text-gray-600">
                      Capacity: {vehicle.capacity} Kg
                    </div>
                  </div>
                  <div className="ml-4 flex flex-col items-end">
                    <div className="text-lg font-bold text-gray-800">
                      ₹{price}
                    </div>
                  </div>
                </div>
              );
            })}
            <Button
              onClick={onBookClick}
              className="w-full mt-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 rounded-md transition disabled:opacity-50"
              disabled={selected === null}
            >
              Book {selected !== null ? data[selected].title : "Any"}
            </Button>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default Book;
